
header #mainMenu #menuEntryContainer .menuEntry > a, header #mainMenu #menuEntryContainer .menuEntry > a:hover {
  padding: 30px 18px;
}

#searchContainer {
  width: 250px;
}

@media (max-width: 988px) {
  header #mainMenu #menuEntryContainer {
    display: none;
  }

  header #burger {
    display: block;
  }
}

@media (max-width: 1190px) {
  .icon.search {
    display: block;
  }

  #searchContainer {
    display: none;
  }
}
/*# sourceMappingURL=header_de.css.map */